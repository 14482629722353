.searchbar {
    width: 360px;
}

.searchbar .input-group-text {
    border-radius: 20px;
    background-color: $hv-light-bg;
    border-right: none;
    padding-right: 0;
}

.searchbar input.form-control {
    border-radius: 20px;
    font-size: 13px;
    font-style: italic;
    background-color: $hv-light-bg;
    border-left: none;
}
