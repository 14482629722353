/*
 * Stylesheet for the "Date Range Selection" section of Consent.
 */

.date-selection-wrapper {
    margin: 0px;
    display: flex;
    flex-direction: column;

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        border-radius: 4px;
    }

    .react-datepicker__input-container input {
        padding: 3px 10px;
    }
}
