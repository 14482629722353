.card {
    min-height: 160px;
    .card-body {
        padding-top: 20px;
        padding-left: 20px;
        .card-title {
            @extend h1;
            .card-title-subtext {
                padding-left: 8px;
                font-size: 11px;
                font-style: italic;
                font-weight: bold;
            }
        }
    }
}

.stats-tile.selected {
    .card {
        background-color: #154a65;
        color: white;
        border: none;
        .card-body {
            .card-title {
                color: white;
            }
        }
    }
    .tile-arrow-down {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -15px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #154a65;
    }
}
.stats-tile.unselected {
    .card {
        background-color: white;
    }
    .tile-arrow-down {
        display: none;
    }
}

.stats-tile.unselected:hover {
    .card {
        .card-body {
            box-shadow: 0px 0px 6px grey;
        }
    }
}

li.list-group-item {
    border: 0;
    background-color: inherit;
    padding: 0px;
    display: flex;
    .stat-name {
        display: inline;
        padding-left: 6px;
        width: 110px;
    }
    .stat-value {
        font-family: $hv-font-medium;
        width: 90px;
    }
    .stat-row-item {
        display: inline;
    }
    .stat-link {
        margin-left: 10px;
        cursor: pointer;
    }
}

.stat-loading-status {
    display: flex;
    align-items: center;
    .stat-retry {
        margin-left: 3px;
    }
}
