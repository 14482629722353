.sidebar {
    overflow-y: scroll;
}

.sidebar-separator {
    border-top: 1px solid rgba(128, 128, 128, 0.6);
}

#filter-candidates-header {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    margin-bottom: 3px;
}
#filter-ids-button {
    margin-top: 10px;
}

#batch-upload-content {
    #batch-upload-link:hover {
        text-decoration: none;
    }
}
