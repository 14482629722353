@mixin btn-gradient($top-color, $bottom-color) {
    background: $top-color;
    background-image: -webkit-linear-gradient(top, $top-color, $bottom-color);
    background-image: -moz-linear-gradient(top, $top-color, $bottom-color);
    background-image: -ms-linear-gradient(top, $top-color, $bottom-color);
    background-image: -o-linear-gradient(top, $top-color, $bottom-color);
    background-image: linear-gradient(to bottom, $top-color, $bottom-color);

    &:hover {
        background: $bottom-color;
        background-image: $bottom-color;
        background-image: $bottom-color;
        background-image: $bottom-color;
        background-image: $bottom-color;
        background-image: $bottom-color;
    }

    height: 25px;
    line-height: 0px; // subtract top and bottom border
}
