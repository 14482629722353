.error-bar {
    position: fixed;
    top: 60px;
    z-index: 1;
    left: 260px;
    right: 0;
    display: block;
    height: 40px;
    text-align: center;
    width: 100%;
    background-color: #d65746;
    padding: 10px;
    font-family: 'Ubuntu-Medium', sans-serif;
    color: #fff;
}
